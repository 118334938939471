html {
  @apply p-3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  width: 100%;
}

p {
  @apply mb-3;
}
