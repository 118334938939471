.SegmentSwitch {
  @apply rounded-lg;
  background-color: var(--grey-20);
  display: grid;
  grid-template-columns: 1fr 1fr;
  transition: box-shadow 150ms ease;

  & label {
    @apply p-2;
    display: block;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    color: var(--grey-90);

    &:first-child {
      @apply rounded-tl-lg rounded-bl-lg;
    }

    &:last-child {
      @apply rounded-tr-lg rounded-br-lg;
    }
  }
}

/* Heads up: brittle HTML structure expected ahead! */

/* Make the active tab label look active. */
.TabContainer:has(input.TabContainer__Trigger:nth-of-type(1):checked) .SegmentSwitch label:nth-child(1),
.TabContainer:has(input.TabContainer__Trigger:nth-of-type(2):checked) .SegmentSwitch label:nth-child(2) {
  @apply rounded-lg;
  background-color: white;
  box-shadow: var(--ring-color) 0 0 0 3px;
  color: var(--blue-80);
  cursor: default;
}

.TabContainer:has(input.TabContainer__Trigger:nth-of-type(1):focus-visible) .SegmentSwitch,
.TabContainer:has(input.TabContainer__Trigger:nth-of-type(2):focus-visible) .SegmentSwitch {
  background-color: var(--grey-30);
  box-shadow: var(--grey-90) 0 0 0 3px;
}
