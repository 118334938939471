.Box {
  @apply p-3 rounded-lg shadow-large;
  background-color: white;
}

.HorizDivider {
  width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-40);

  & span {
    padding: 0.25em 0;
    background-color: white;
  }
}

.lobby-form-cols {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);

  @media screen and (max-width: 1075px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    width: 100% !important;

    .HorizDivider {
      display: none;
    }
  }
}

.TabContainer {
  & input[type="radio"] {
    position: absolute;
    left: -9999px;

    &:checked + .TabContainer__Tab {
      display: block;
    }
  }

  &__Tab {
    display: none;
  }

  &__Label {
    all: unset;
  }
}
