/*
MATERIAL INPUT

This is like the Material style text input which has a placeholder
that animates up to look like a title/label.
----------------------------------------------------------------*/

/* TODO: All this mess of declaring all type="" selectors is that we declare them as this in
Lookbook's forms.css. We should move away from that and only do 'input' or something similar. */

:is(input[type='text'], input[type='password'], input[type='email'], input[type='date'], textarea, select).MaterialInput {
  --wanted-vertical-padding: 1.4em;
  --padding-x: .75em;
  --padding-y: calc(var(--wanted-vertical-padding) / 2);

  position: relative;
  padding: var(--padding-y) var(--padding-x);
  font-weight: bold;

  &--has-label {
    --padding-y: 1.1em;
    padding-bottom: calc(var(--wanted-vertical-padding) - var(--padding-y));
  }

  &:is(select) {
    padding-right: calc(var(--padding-x) + 1.5em);
    background-position: center right var(--padding-x);
  }

  & + label {
    --transition-dur: 120ms;
    color: var(--placeholder-color);
    pointer-events: none;
    font-weight: bold;
    position: absolute;
    left: .75em; /* Must be --padding-x, can't inherit the variable :( */
    top: 0;
    transform: translate(0, 110%);
    line-height: 1;
    transition: transform ease, color ease;
    transition-duration: var(--transition-dur);
    transform-origin: left top;
  }

  &:is(input[type='date']) {
    cursor: pointer;
  }

  /* For when :focusing: */
  &:focus,
  /* For when the field has a value already: */
  &:not(:placeholder-shown),
  /* For when the <select> has a value other than the default one: */
  &:is(select, input[type="date"]).MaterialInput--selected,
  /* Browser autofill has set a value. */
  &:autofill {
    & + label {
      color: var(--grey-80);
      transform: translate(0, .5em) scale(0.75);
    }
  }
}

input[type='date'].MaterialInput:not(:focus),
select.MaterialInput {
  &:not(.MaterialInput--selected) {
    /* Trick to hide the top default <option> */
    color: transparent;
  }
}

select.MaterialInput option {
  color: var(--grey-100);
}
